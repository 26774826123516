.buttonStyle {
  border-radius: 1.5rem!important;
  height: 3rem;
  margin-bottom: 0.5rem!important;
  padding: 0!important;
  width: 3rem;
}

.globalSitesDiv {
  background-color: white;
  padding: 2rem;
  position: relative
}

@media (max-width: 767px) {
  .globalSitesDiv {
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

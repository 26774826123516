.nowPlayingDiv {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 500px;
  padding: 0 0.75rem;
  position: relative;
}

.introText {
  margin-top: 0.75rem;
}

.carousel-root {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.carousel .carousel-slider {
  border: 1px solid grey;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
  width: 100%;
}

.flag {
  font-size: 2rem!important;
  left: 5px!important;
  position: absolute;
  top: 5px!important;
  z-index: 1000;
}

.year {
  background-color: rgba(255,255,255,0.85);
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 1rem!important;
  font-weight: bold;
  padding: 1px 4px;
  position: absolute;
  top: 6px!important;
  right: 5px!important;
  z-index: 1000;
}

li.slide {
  display: flex!important
}

.nowPlayingTextDiv {
  margin: 0.75rem 0;
}

p.nowPlayingTextSong, p.nowPlayingTextArtist {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

p.nowPlayingTextArtist {
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
}

p.nowPlayingTextSong {
  margin-bottom: 1rem;
}

.nowPlayingTextSlide {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.5rem;
}

.nowPlayingImageSlide {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .nowPlayingDiv {
    width: 100%;
  }
}

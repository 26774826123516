@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  font-family:"Inter Twemoji Country Flags", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.ui.text.container {
  font-family:"Inter", 'Helvetica Neue', sans-serif!important;
}

a {
  color: #152AF7!important;
}

#root {
  background-color: #6320EE;
  font-family: 'Inter', sans-serif;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

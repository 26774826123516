.eventDiv {
    align-self: center;
    background-color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    bottom: 0;
    display: flex;
    margin: auto;
    padding: 0.25rem;
    position: fixed;
    width: inherit;
    z-index: 1000;
}

.dateDiv {
    align-self: center;
    color: black;
    flex-direction: column;
    justify-items: center;
    margin-right: 10px
}

.dayDiv {
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
}

.monthDiv {
    background-color: #6320EE;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 0 5px;
    text-align: center;
}

.eventTextDiv {
    align-self: center;
    flex-direction: column;
    font-size: 0.85rem;
    align-items: flex-start;
}
.eventNameDiv {
    font-weight: bold;
    text-decoration: underline;
}

.eventLocationDiv {
    color: black;
}

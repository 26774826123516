html {
  background-color: #6320EE!important;
}

.container {
  background-color: white;
  position: relative;
  min-height: 100vw;
}

.logoDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0;
  padding-top: 0.5rem;
}

.imgWrapper {
  max-width: 20%;
  text-align: center;
  margin: auto
}

.imgWrapper > a > img {
  max-width: 100%;
  height: auto;
}

.imgWrapperASP > a > img {
  max-width: 70%;
}

.topDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logoutButtonDiv {
  padding: 0.5rem
}

.welcomeDiv {
  padding: 0.5rem
}

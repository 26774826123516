.historyDiv {
  width: 100%;
}

.listText {
  font-size: 0.9rem;
  overflow-wrap: break-word;
  text-transform: capitalize;
}

.listImg {
  width: 64px;
}

div.item {
  display: flex!important;
}

.description > p {
  line-height: 1rem!important;
  margin-top: 0.2rem!important;

}

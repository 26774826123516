.ui.table thead tr:first-child>th:last-child {
    z-index: 1000;
}

.ui.table.ui.table.subTable thead tr:first-child>th:last-child {
    z-index: 500;
}

.ui.table.subTable thead th {
    text-align: center;
}

.ui.table.subTable thead tr>th {
    background-color: lightgray;
    color: black
}

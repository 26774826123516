.ui.attached.tabular.menu {
  overflow-x: scroll;
}

.ui.table thead tr>th {
  position: sticky;
  top: 0;
  background-color: #333;
  color: white;
  z-index: 1;
}

.ui.modal {
  width: auto;
}

.adminDiv {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-left: -20vw;
  margin-right: -20vw;
  position: relative
}

.ui.table td {
  font-size: 0.9rem!important;
}

.titleDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  padding: 0.5rem
}

.compactInput {
  padding: 0;
  width: 70px!important
}

.mediumInput {
  padding: 0;
  width: 100px!important
}

.ui.table thead th {
  padding: 5px!important
}

.spotifyButton {
  background-color: #1Db954;
  border-radius: 10px;
  color: black;
  display: block;
  font-weight: bolder;
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  width: 10rem;
}

.mixedPlaylistNames {
  font-weight: normal;
}

.spotifyButton:hover {
  color: white;
}

.ui.table {
  font-size: 1rem!important;
}

.refreshModal {
  background-color: white;
  border: 2px solid #6320EE;
  border-width: 2px 0 0 0;
  bottom: 0;
  left:0;
  padding: 5px;
  position: fixed;
  width: 100%!important
}

@media (max-width: 767px) {
  .adminDiv {
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

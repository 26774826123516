.loginPageDiv {
  padding: 2rem
}

.loginDiv {
  background-color: #6320EE;
  bottom: 0;
  margin: 0;
  max-width: 700px;
  padding: 0.5rem;
  position: fixed;
  width: 100%
}

.loginText {
  padding: 0 1rem;
  min-width: 120px
}

.loginText p {
  color: white;
  font-weight: bold;
}

.introLoginText {
  text-align: center;
}

.playlistsTitle {
  margin: 1rem 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.ui.form .field .ui.input input, .ui.form .fields .field .ui.input input {
  font-size: 16px;
}

.ui.form .field:last-child, .ui.form .fields:last-child .field {
  margin-bottom: 0!important;
}

@media (max-width: 767px) {
  .loginDiv {
    padding: 10px;
    width: 100%!important
  }
  .loginText {
    display:none
  }
  .ui.form .fields {
    flex-wrap: nowrap!important;
    /* align-items: space-around */
  }
  .ui.input {
    width: auto!important
  }
}

.nowPlayingDiv.mini {
  border: 1px solid lightgrey;
  max-width: 300px;
  padding-bottom: 10px;
}

.mini .carousel-root {
  max-width: 200px;
  margin: auto;
}

.ui.button.instaButton {
  background-color: white!important;
}

.nowPlayingDiv.mini {
  width: 300px!important;
}


.nowPlayingTextDiv.mini {
  margin: 0;
  display: flex;
  justify-content:space-evenly
}

.mini p.nowPlayingTextSong, p.nowPlayingTextArtist {
  font-size: 1rem;
  width: 100%;
  /* text-align: left; */
}

.mini p.nowPlayingTextArtist {
  font-size: 1rem;
}

.mini p.nowPlayingTextSong {
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .nowPlayingDiv.mini {
    max-width: 350px;
  }
}

.artistDiv {
  height: 100%;
  padding: 2rem;
}

.tiktokIcon {
  margin-top: 10px!important;
}

.ui.form textarea, .ui.form input {
  font-size: 16px
}
